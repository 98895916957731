export const environment = {
  production: false,
  renbaseUrl: 'https://portal-extqa.petinsurance.com/api/',
  baseUrl: 'https://api-test.nwie.net/',
  orchBaseUrl: 'https://orchqaf.petinsurance.com/api/',
  accountsUrl: 'https://orchf.petinsurance.com/api/',
  qecBaseUrl: 'https://qecqa.petinsurance.com/',
  vpiBaseUrl: 'https://vpiqa.petinsurance.com/',
  reCaptchaEnabled: '#{{ENABLE_RECAPTCHA}}#',
  verifyReCaptchaUrl: 'https://api.nationwide.com/security-processing/recaptcha/v2/projects/recaptcha-270122/assessments',
  NWDeveloperClientId: 'jehmABYPA0chuJcpcjnCTmtli45zZsgL',
  reCaptchaSiteKey: '6Le1V98UAAAAAJELaV2_CZC8Y7pHmD7bB2pXLyGw',
  newRelic: '/assets/js/new-relic.js',
  referralUrl: ' https://www.petinsurance.com/referral',
  sweepStakesQecUrl: 'https://qecqa.petinsurance.com/?OM=PH0003',
  ensightenBootstrap: '//tags.nationwide.com/test/Bootstrap.js',
  nationwiideUnumRedirectUrl: 'https://api.identity-test.nwie.net/information-risk-management/enterprise-user-auth/v3/MEMBER-PET-UNUM/authorize?client_id=nAbjFYdimtBtXixuszvHHQ5JeDLcUPH7&response_type=code&redirect_uri=https://sample.nationwide.com&scope=nwapi%20openid&state=Integrated%20interactive%20challenge&nonce=XF7EHyMGqU54iBF&code_challenge=hAr_hM2fw-p-pagxc5S1_1o5kYJEnoL7Xzm6ebBmRrc&code_challenge_method=S256',
  nationwiideRedirectUrl: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize?client_id=' +
    '9R6VgQawx5JOj7KMGXvg42AscHD64qs5&redirect_uri=https://my-qa.petinsurance.com&response_type=token+id_token&scope=openid&nonce=1&auth_method=ping&realm=member&message_id=1',
  nationwiideRedirectCFUrl: 'https://api-test.nwie.net/security-processing/enterprise-user-auth/v2/authorize?client_id=' +
    '9R6VgQawx5JOj7KMGXvg42AscHD64qs5&redirect_uri=https://d1jcvd9fh2n08.cloudfront.net&response_type=token+id_token&scope=openid&nonce=1&auth_method=ping&realm=member&message_id=1',
  nationwideLogOut: 'https://pt-myaccount.nationwide.com/myaccount/Logout.action',
  nwUpdateUsernameAndPassword: 'https://st-myaccount.nationwide.nwie.net/myaccount/profile/RetrieveUserContactInformation.action',
  //nwUpdateUsernameAndPassword: 'http://pt-myaccount.nationwide.com/myaccount/profile/RetrieveUserContactInformation.action',
  /*
   nationwideRedirectClientId: '9R6VgQawx5JOj7KMGXvg42AscHD64qs5',
   nationwiideRedirectUrl: 'https://api-stage.nationwide.com/security-processing/enterprise-user-auth/v2/authorize?client_id=' +
     'gdqxCd99Mx6u7EExxsbXYVhAGG3cJTYX&redirect_uri=https://portalqa.petinsurance.com&response_type=token+id_token&scope=openid&nonce=1&auth_method=ping&realm=member&message_id=1',
   nationwideLogOut: 'https://pt-myaccount.nationwide.nwie.net/myaccount/Logout.action',
   nwUpdateUsernameAndPassword: 'https://pt-myaccount.nationwide.nwie.net/myaccount/profile/RetrieveUserContactInformation.action',
   */
  cardProfileUrl: 'https://apipci-test.nwie.net/financial-management/pet-paymentech-gateway/v1/cardprofiles',
  messageId: 'PetQuoteEnrollment',
  partnershipMessageId: 'testfy',
  paymentGatewayClientId: '7OAieeR1lxKxuGIAp8z3oTja9D1oGEAk',
  partnershipClientId: 'hH20YhFQmfsbAnCjVFQJWXGdm9lzs0jZ',
  partnershipAccessToken: 'fPocDVDKSSXcCADtAkv36HeEN6Jv', 
  portalContentful: '2YpgzgRqruKF99dFhjnaYJ',

  contentful: {
    space: '440y9b545yd9',
    environment: 'master',
    accessToken: '3vkS-gYKC4-cjB2Ba47AVT8y9KUaWZMrpDsNnc6sIas'
  },
  surchargeUrl: 'https://assets.ctfassets.net/440y9b545yd9/3W5gW5nYnUCbJSCYl5C8qY/95f2279022c33bdc3c38de085bca3bf1/State_Surcharge.json',

  nationwideLoginUrl: 'https://pt-login.nationwide.com/access/web/login.htm',
  nationwideFUPUrl: 'https://pt-login.nationwide.com/access/retrieve-username/verify-user.htm',
  nationwideCreateAccountUrl: 'https://pt-login.nationwide.com/access/register/signon-standard.htm',

  nationwideRefreshURL: 'https://identity-pt.nationwide.com/bsa-login-refresh/refresh?instance=BSAOAuthB2CNW&resumeURL=https://my-qa.petinsurance.com/refreshpage.html',
  nationwideRefreshTokenUrl: 'https://api-dev.nwie.net/security-processing/enterprise-user-auth/v2/authorize?client_id=' +
    '9R6VgQawx5JOj7KMGXvg42AscHD64qs5&redirect_uri=https://my-qa.petinsurance.com/refreshtoken.html&response_type=token+id_token&scope=openid&nonce=1&auth_method=ping&realm=member&message_id=1',

  beaconJs: '//cdn.evgnet.com/beacon/nationwidepet/nwpet_dev/scripts/evergage.min.js',
  client_id: 'xWjSbq2uPhvTChHbIC0YGETrKfttAGJu',
  splunkToken: '6AF26D9C-AFA8-483F-AB2D-569EE1CE63B9',
  applicationClientId: '7NNowOQQfbFeotAT2haE3IQkmtwZGak6',
  fullStoryOrgId: 'KJMMK',
  env: 'qa',
  euaV3Configuration: {
    authority: "https://api.identity-test.nwie.net/information-risk-management/enterprise-user-auth/v3/MEMBER-PET-UNUM",
    redirectUrl: "https://my-qa.petinsurance.com",
    postLogoutRedirectUri: "https://my-qa.petinsurance.com",
    clientId: "xWjSbq2uPhvTChHbIC0YGETrKfttAGJu",
    scope: "nwapi openid",
    responseType: "code",
    silentRenew: true,
    useRefreshToken: true,
  },
  breedsAPIConfig: {
    breedApiClientId: '2He01cIgTxGxoUndcATKxE4MnRsZ0hKS',
    renBreedUri: 'product-pricing/pet-product-presentation/v1',
    petBreedUri: 'customer-aquisition/pet-qec-products/v1'
  }
};
